import React from "react";
import * as styles from "./MainIntro.module.scss";


import background from "../../assets/illustrations/backgroundMainIntro.svg";
import backgroundResponsive from "../../assets/illustrations/backgroundMainIntroResponsive.svg";
import xRay from "../../assets/illustrations/backgroundMainIntroRays.svg";
import logo from "../../assets/images/logoKolabsMain.png";


const MainIntro = () => {
    return (
        <div className={styles.container}>
            <img className={styles.background} src={background} alt={"à propos de KOLABS"} />
            <img className={styles.backgroundResponsive} src={backgroundResponsive} alt={"à propos de KOLABS"} />            
            <img className={styles.logo} src={logo} alt={"Logo KOLABS"} />
            <p id="sectionMainIntro_id" className={styles.aboutUsText}>
                KOLABS c'est une entreprise à taille humaine qui s'adapte à vos besoins et vous permet de trouver les perles rares de l'IT.<br />
                C'est votre partenaire pour acquérir rapidement et durablement
                de nouveaux talents.
            </p>
            <img className={styles.xray} src={xRay} alt={"Logo KOLABS"} />
        </div>
    )
}

export default MainIntro;
// extracted by mini-css-extract-plugin
export var header = "Header-module--header--Wcvsx";
export var headerContentDesctopScreen = "Header-module--headerContentDesctopScreen--TQkd-";
export var headerContentMobileScreen = "Header-module--headerContentMobileScreen--k+AxE";
export var logo = "Header-module--logo--vXfki";
export var hamburgerIconButton = "Header-module--hamburgerIconButton--kiZC7";
export var hamburgerIcon = "Header-module--hamburgerIcon--L2kLG";
export var dropDownContainer = "Header-module--dropDownContainer--ZjnzG";
export var dropDownLinkContainer1 = "Header-module--dropDownLinkContainer1--rwGAO";
export var rotateX = "Header-module--rotateX--Bgztr";
export var dropDownLinkContainer2 = "Header-module--dropDownLinkContainer2--SAUg1";
export var dropDownLinkContainer3 = "Header-module--dropDownLinkContainer3--IiU2h";
export var dropDownLinkContainer4 = "Header-module--dropDownLinkContainer4--XlfUM";
export var dropDownLinkContainer5 = "Header-module--dropDownLinkContainer5--6Y7On";
export var dropDownLinkContainer = "Header-module--dropDownLinkContainer--xyk3l";
export var dropDownLink = "Header-module--dropDownLink--vPkH4";
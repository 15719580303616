import React from "react";
import * as styles from "./ourValues.module.scss";
import background from "../../assets/illustrations/backgroundOurValue.svg";
//=======================================================
import kBlock from "../../assets/illustrations/ourValues/kBlock.svg";
import oBlock from "../../assets/illustrations/ourValues/oBlock.svg";
import lBlock from "../../assets/illustrations/ourValues/lBlock.svg";
import aBlock from "../../assets/illustrations/ourValues/aBlock.svg";
import bBlock from "../../assets/illustrations/ourValues/bBlock.svg";
import sBlock from "../../assets/illustrations/ourValues/sBlock.svg";
//=======================================================


const OurValues = () => {
    return (
            <div className={styles.container}>
                <div className={styles.blocTitle}>KNOW HOW</div>
                <p className={styles.blocText}>Nous travaillons avec des collaborateurs experts dans leur domaine et les accompagnons pour qu'ils restent performants.</p>
                <div className={styles.blocTitle}>OUTSTANDING</div>
                <p className={styles.blocText}>Nous recherchons des personnalités et sommes attentifs au potentiel de chacun. Nous encourageons et accompagnons chaque consultant à élargir ses connaissances afin de réaliser ses ambitions professionnelles.</p>
                <div className={styles.blocTitle}>LOYALITY</div>
                <p className={styles.blocText}>Nous assurons un suivi privilégié et personnalisé de tous nos collaborateurs pendant leur mission et tout au long de leur carrière. </p>
                <div className={styles.blocTitle}>AGILITY</div>
                <p className={styles.blocText}>L'expertise de nos consultants et l'accompagnement que nous mettons en place leur permet de s'adapter rapidement aux environnements de leurs missions afin d'être très vite opérationnels.
                </p>
                <div className={styles.blocTitle}>BEHAVIOR</div>
                <p className={styles.blocText}>La personnalité et le potentiel de chacun sont des éléments centraux, car nous avons à coeur de travailler avec des personnes qui partagent nos valeurs.</p>
                <div className={styles.blocTitle}>SHARING</div>
                <p className={styles.blocText}>Nous faisons en sorte de maintenir une forte cohésion entre nos collaborateurs au travers différents canaux de communication, afin que chacun soit impliqué et se sente acteur de KOLABS.
                </p>
                <img className={styles.background} src={background} alt="Les valeurs de KOLABS" />
            </div>
    )
}


const OurValuesResponsive = () => {
    return (
        <div className={styles.containerResponsive}>
            <div className={styles.singleValueContainer}>
                <div className={styles.singleValueTitle}>KNOW HOW</div>
                <p className={styles.singleValueText}>Nous travaillons avec des collaborateurs experts dans leur domaine et les accompagnons pour qu'ils restent performants.</p>
                <img className={styles.singleValueImage} src={kBlock} alt={"illustration bloc les valeurs de KOLABS"} />
            </div>
            <div className={styles.singleValueContainer}>
                <div className={styles.singleValueTitle}>OUTSTANDING</div>
                <p className={styles.singleValueText}>Nous recherchons des personnalités et sommes attentifs au potentiel de chacun. Nous encourageons et accompagnons chaque consultant à élargir ses connaissances afin de réaliser ses ambitions professionnelles.</p>
                <img className={styles.singleValueImage} src={oBlock} alt={"illustration bloc les valeurs de KOLABS"} />
            </div>
            <div className={styles.singleValueContainer}>
                <div className={styles.singleValueTitle}>LOYALTY</div>
                <p className={styles.singleValueText}>Nous assurons un suivi privilégié et personnalisé de tous nos collaborateurs pendant leur mission et tout au long de leur carrière.</p>
                <img className={styles.singleValueImage} src={lBlock} alt={"illustration bloc les valeurs de KOLABS"} />
            </div>            <div className={styles.singleValueContainer}>
                <div className={styles.singleValueTitle}>AGILITY</div>
                <p className={styles.singleValueText}>L'expertise de nos consultants et l'accompagnement que nous mettons en place leur permet de s'adapter rapidement aux environnements de leurs missions afin d'être très vite opérationnels.
                </p>
                <img className={styles.singleValueImage} src={aBlock} alt={"illustration bloc les valeurs de KOLABS"} />
            </div>            <div className={styles.singleValueContainer}>
                <div className={styles.singleValueTitle}>BEHAVIOR</div>
                <p className={styles.singleValueText}>La personnalité et le potentiel de chacun sont des éléments centraux, car nous avons à cœur de travailler avec des personnes qui partagent nos valeurs.</p>
                <img className={styles.singleValueImage} src={bBlock} alt={"illustration bloc les valeurs de KOLABS"} />
            </div>            <div className={styles.singleValueContainer}>
                <div className={styles.singleValueTitle}>SHARING</div>
                <p className={styles.singleValueText}>Nous faisons en sorte de maintenir une forte cohésion entre nos collaborateurs au travers différents canaux de communication, afin que chacun soit impliqué et se sente acteur de KOLABS.
                </p>
                <img className={styles.singleValueImage} src={sBlock} alt={"illustration bloc les valeurs de KOLABS"} />
            </div>
        </div>
    )
}

export { OurValues, OurValuesResponsive };
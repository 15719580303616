import React, { useState } from "react";
import * as styles from "./OurBranches.module.scss";

import background from "../../assets/illustrations/backgroundOurBranches.svg";
import leftIllustration from "../../assets/illustrations/backgroundOurBranchesLeftIllustration.svg";
import rightIllustration from "../../assets/illustrations/backgroundOurBranchesRightIllustration.svg";


const OurBranches = () => {

    const [isVisibleContent, setIsVisibleContent] = useState(false);
    const showContent = () => {
        setIsVisibleContent(!isVisibleContent);
    }


    return (
        <div className={styles.container}>
            <img alt="illustration les branches de kolabs watch et kolabs studio " className={styles.illustration} src={background} />
            <img alt="illustration kolabs watch" className={styles.leftIllustration} src={leftIllustration} />
            <img alt="illustration kolabs studio" className={styles.rightIllustration} src={rightIllustration} />
            <div className={styles.unitsContainer}>
                <div className={styles.singleUnitContainer + " " + styles.leftContainer}>
                    <h3 className={styles.singleUnitTitle}>KOLABS Watch</h3>
                    <div className={styles.singleUnitText}>Notre B.U. dédiée à la cybersécurité</div>
                    <button onClick={showContent} className={styles.button}>
                        {
                            isVisibleContent ? "Fermer" : "En savoir +"
                        }
                    </button>
                </div>
                <div className={styles.singleUnitContainer + " " + styles.rightContainer}>
                    <h3 className={styles.singleUnitTitle}>KOLABS Studio</h3>
                    <div className={styles.singleUnitText}>Notre B.U. dédiée au développement</div>
                </div>
            </div>
            <div className={styles.contentContainer + " " + (isVisibleContent && styles.isVisibleContent)}>
                {
                    isVisibleContent &&
                    <div className={styles.content}>
                        <b>KOLABS Watch est un pure player en cybersécurité capable de vous accompagner sur vos enjeux cyber au travers de différentes offres :</b>
                        <ul>
                            <li>
                                Conseil en sécurité de l'information :
                                <ul>
                                    <li>Gouvernance SSI (RSSI as a Service)</li>
                                    <li>Gestion des risques (27 005, EBIOS RM)</li>
                                </ul>
                            </li>
                            <li>
                                Conformité :
                                <ul>
                                    <li>Accompagnement RGPD</li>
                                    <li>Accompagnement à la certification 27 001</li>
                                    <li>Accompagnement à la certification HDS(Hébergement de Données de Santé)</li>
                                    <li>Mise en conformité règlementaire (Directive NIS, RGS, LPM)</li>
                                </ul>
                            </li>
                            <li>
                                Audit :
                                <ul>
                                    <li>Test d'intrusion</li>
                                    <li>Audit documentaire</li>
                                    <li>Audit d'architecture</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                }
            </div>
            {/* <div className={styles.contentContainer + " " + (isVisibleContent && styles.isVisibleContent)}>
                {
                    isVisibleContent &&
                    <div className={styles.content}>
                        <b>KOLABS Watch est un pure player en cybersécurité capable de vous accompagner sur vos enjeux cyber au travers de différentes offres :</b>
                        <ul>
                            <li>Conseil : Prestation à forte valeur ajoutée</li>
                            <li>Gestion de risque</li>
                            <li>Conformité (RGPD, Directive NIS, ISO 27 001, HDS)</li>
                            <li>Accompagnement Certification (ISO 27 001, HDS)</li>
                            <li>Elaboration de politique et de procédure technique</li>
                            <li>Audit</li>
                            <li>Audit Technique, Pentest, Test d'intrusion, Audit documentaire, Audit d'architecture.</li>
                            <li>RSSI as a Service</li>
                            <li>Offre sur mesure pour vous accompagner dans la sécurisation de votre SI</li>
                        </ul>
                    </div>
                }
            </div> */}
        </div>
    )
}

export default OurBranches;
import React from "react";
import linkedinIcon from "../../assets/icons/linkedinIconBlack.svg"
import * as styles from "./MeetUsBlock.module.scss";

const MeetUsBlock = () => {
    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h2>On se rencontre ?</h2>
                <div className={styles.meetUsSingleBlockContainer}>
                    <div className={styles.leftElement}>Un café ?</div>
                    <div className={styles.rightElement}>26 rue de la Bienfaisance, 75008 Paris</div>
                </div>
                <div className={styles.meetUsSingleBlockContainer}>
                    <div className={styles.leftElement}>Une question ?</div>
                    <div className={styles.rightElement}>
                        <a href="tel:0033975902516">01 88 24 00 90</a>
                    </div>
                </div>
                <div className={styles.meetUsSingleBlockContainer}>
                    <div className={styles.leftElement}>Un mot doux ?</div>
                    <div className={styles.rightElement}>
                        <a rel="noopener" href="Mailto:hello@kolabs.fr">hello@kolabs.fr</a>
                    </div>
                </div>
                <div className={styles.meetUsSingleBlockContainer}>
                    <div className={styles.leftElement}>Suivez-nous</div>
                    <div className={styles.rightElement}>
                        <a rel="noreferrer" href={"https://www.linkedin.com/company/kolabs/"} target="_blank">
                            <img className={styles.icon} alt="Suivez-nous sur linkedin" src={linkedinIcon} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetUsBlock;
// extracted by mini-css-extract-plugin
export var ourValuesSection = "index-module--ourValuesSection--Q73CO";
export var ourValuesSectionResponsive = "index-module--ourValuesSectionResponsive--obUhv";
export var sectionJoinus = "index-module--sectionJoinus--EIXWo";
export var joinusLinks = "index-module--joinusLinks--0CTO5";
export var buttonLink = "index-module--buttonLink--Xui+U";
export var sectionContactUs = "index-module--sectionContactUs--gkHnS";
export var contactUsContainer = "index-module--contactUsContainer--MNSqq";
export var contactUsText = "index-module--contactUsText--Z2RkU";
export var contactUsButtonLink = "index-module--contactUsButtonLink--Gn3zX";
export var sectionWhyJoinUs = "index-module--sectionWhyJoinUs--+ggcl";
export var sectionIBlocks = "index-module--sectionIBlocks--7WvA2";
export var sectionIBlockTitleContainer = "index-module--sectionIBlockTitleContainer--Tn0Oj";
export var iBlocksContainer = "index-module--iBlocksContainer--wxZQP";
export var backgroundIBlocks = "index-module--backgroundIBlocks--+hNtF";
export var iblocks = "index-module--iblocks--9wzt1";
export var offersSection = "index-module--offersSection--sKim1";
export var offersSectionTitle = "index-module--offersSectionTitle--4Sxyg";
export var offersBackground = "index-module--offersBackground--zE89P";
export var offersSupContainer = "index-module--offersSupContainer--28R0A";
export var offersContainer = "index-module--offersContainer--tjwFf";
export var sectionTeam = "index-module--sectionTeam--XnZ9t";
export var sectionImplentations = "index-module--sectionImplentations--XcI5u";
export var sectionSteps = "index-module--sectionSteps--E2pbS";
export var steps = "index-module--steps--15AcD";
export var backgroundStepsContainer = "index-module--backgroundStepsContainer--daSeK";
export var stepsIllustration = "index-module--stepsIllustration--Hf98Z";
export var stepsMainTextContainer = "index-module--stepsMainTextContainer--wjBaG";
export var stepsMainText = "index-module--stepsMainText--yivKE";
export var stepsContainer = "index-module--stepsContainer--hj1qM";
export var sectionOurBranches = "index-module--sectionOurBranches--xVgja";
export var partnersCarousel = "index-module--partnersCarousel--n7ngk";
export var sectionMeetUs = "index-module--sectionMeetUs--drwTc";
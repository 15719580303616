import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import * as styles from "./mainLayout.module.scss";


const MainLayout = ({ children }) => {
    return (
        <div>
            <Header/>
            <main className={styles.mainContainer}>{children}</main>
            <Footer />
        </div>
    )
}

export default MainLayout;
import React from "react";
import * as styles from "./Implementations.module.scss";
import frenchFlag from "../../assets/illustrations/frenchFlag.svg";
import spanishFlag from "../../assets/illustrations/spanishFlag.svg";


const Implementations = () => {
    return (
        <div className={styles.container}>
            <div className={styles.singleImplementationContainer}>
                <img className={styles.flag} src={frenchFlag} alt="Agence ESN KOLABS Paris" />
                <p className={styles.flagText}>
                    KOLABS France<br />
                    26 rue de la Bienfaisance 75008<br />
                    hello@kolabs.fr<br />
                    +33 188 24 00 90<br />
                </p>
            </div>
            <div className={styles.singleImplementationContainer}>
                <img className={styles.flag} src={spanishFlag} alt="KOLABS barcelone espagne" />
                <p className={styles.flagText}>
                    KOLABS Iberica SL<br />
                    Travessera de Gracia 18 08021 Barcelona<br />
                    hola@kolabs.es<br />
                    +34 932 71 97 77<br />
                </p>
            </div>
        </div>
    )
}

export default Implementations;
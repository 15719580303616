import React, { useState } from "react";
import * as styles from "./OfferBlock.module.scss";

const OfferBlock = ({ title, titleExplanation, contentTitle, contentInitialization, content, contentConclusion }) => {
    const [isVisibleContent, setIsVisibleContent] = useState(false);

    const showContent = () => {
        setIsVisibleContent(!isVisibleContent);
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleBlockContainer}>
                <h3 className={styles.title}>{title}</h3>

                {titleExplanation &&
                    <p className={styles.titleExplanation}>
                        {titleExplanation}
                    </p>
                }
                <button onClick={showContent} className={styles.button}>
                    {
                        isVisibleContent ? "Fermer" : "En savoir +"
                    }
                </button>
            </div>
            {
                <div className={styles.content + " " + (isVisibleContent && styles.isVisibleContent)}>
                    <h4 className={styles.contentTitle}>
                        {contentTitle}
                    </h4>

                    {
                        contentInitialization &&
                        <h4 >{contentInitialization}</h4>
                    }

                    <ul>
                        {content && content.map((singleContent, index) => {
                            return (
                                <li key={index}>{singleContent}</li>
                            )
                        })}
                    </ul>
                    {
                        contentConclusion && <p>{contentConclusion}</p>
                    }
                </div>
            }
        </div>
    )
}

export default OfferBlock;
import React, { useState } from "react";
import * as styles from "./Header.module.scss";
import kolabsBrand from "../../assets/images/logoImageWhite.svg";
import dropDownOpenedIcon from "../../assets/icons/dropDownOpened.svg";
import dropDownClosedIcon from "../../assets/icons/dropDownClosed.svg";

const Header = () => {
    const [isVisibleDropDown, setIsVisibleDropDown] = useState(false);

    const hanldeHamburgerClick = () => {
        setIsVisibleDropDown(!isVisibleDropDown);
    }

    const goTo = (sectionTeam_id) => {
        setIsVisibleDropDown(false)

        const top = document.getElementById(sectionTeam_id).offsetTop;

        window.scrollTo({
            top : top - 100,
            left: 0,
            behavior : 'smooth'
        })
    }


    const DropDown = () => {
        return (
            <div className={styles.dropDownContainer}>
                <div className={styles.dropDownLinkContainer1}>
                    <button onClick={() => goTo("sectionMainIntro_id")} className={styles.dropDownLink}>A propos de nous</button>
                </div>
                <div className={styles.dropDownLinkContainer2}>
                    <button onClick={() => goTo("sectionJoinUs_id")} className={styles.dropDownLink}>Nous rejoindre</button>
                </div>
                <div className={styles.dropDownLinkContainer3}>
                    <button  onClick={() => goTo("sectionTeam_id")} className={styles.dropDownLink}>Notre équipe</button>
                </div>
                <div className={styles.dropDownLinkContainer4}>
                    <button  onClick={() => goTo("sectionSteps_id")} className={styles.dropDownLink}>Expérience KOLABS</button>
                </div>

                <div className={styles.dropDownLinkContainer5}>
                    <button  onClick={() => goTo("sectionMeetUs_id")} className={styles.dropDownLink}>Contact</button>
                </div>
            </div>
        )
    }

    return (
        <header className={styles.header}>
            <div className={styles.headerContentMobileScreen}>
                <img className={styles.logo} alt="logo kolabs" src={kolabsBrand} />
                <button className={styles.hamburgerIconButton} onClick={() => hanldeHamburgerClick()} type="button">
                    {
                        isVisibleDropDown
                            ?
                            <img className={styles.hamburgerIcon} alt="liste des url du site internet" src={dropDownOpenedIcon} />
                            :
                            <img className={styles.hamburgerIcon} alt="liste des url du site internet" src={dropDownClosedIcon} />
                    }
                </button>
            </div>
            {
                isVisibleDropDown && <DropDown />
            }
            <div className={styles.headerContentDesctopScreen}>
                <img className={styles.logo} alt="logo kolabs" src={kolabsBrand} />
                <button  onClick={() => goTo("sectionMainIntro_id")} className={styles.dropDownLink}>A propos de nous</button>
                <button  onClick={() => goTo("sectionJoinUs_id")} className={styles.dropDownLink}>Nous rejoindre</button>
                <button  onClick={() => goTo("sectionTeam_id")} className={styles.dropDownLink}>Notre équipe</button>
                <button  onClick={() => goTo("sectionSteps_id")} className={styles.dropDownLink}>Expérience KOLABS</button>
                <button  onClick={() => goTo("sectionMeetUs_id")} className={styles.dropDownLink}>Contact</button>
            </div>
        </header>
    )
}

export default Header;
// extracted by mini-css-extract-plugin
export var container = "OurBranches-module--container--Hlm+Q";
export var leftContainer = "OurBranches-module--leftContainer--CIrJA";
export var rightContainer = "OurBranches-module--rightContainer--+bAoE";
export var unitsContainer = "OurBranches-module--unitsContainer--CtpGj";
export var singleUnitContainer = "OurBranches-module--singleUnitContainer--ssutE";
export var singleUnitTitle = "OurBranches-module--singleUnitTitle--cOx+-";
export var singleUnitText = "OurBranches-module--singleUnitText--i76f5";
export var button = "OurBranches-module--button--oB5wh";
export var contentContainer = "OurBranches-module--contentContainer--aCQUl";
export var content = "OurBranches-module--content--2pY9y";
export var isVisibleContent = "OurBranches-module--isVisibleContent--y25Yl";
export var illustration = "OurBranches-module--illustration--aKd4u";
export var leftIllustration = "OurBranches-module--leftIllustration---QD9T";
export var rightIllustration = "OurBranches-module--rightIllustration--zKwzB";
import React from "react"
import *as styles from "./JoinUs.module.scss";
import illustrationJoinUs from "../../assets/illustrations/illustrationJoinUs.svg";
import backgroundWhyJoiningUs from "../../assets/illustrations/backgroundWhyJoiningUs.svg"


const JoinUs = () => {

    return (
        <div>
            <div className={styles.titlteContainer}>
                <h2 className={styles.title}>NOUS REJOINDRE</h2>
                <img className={styles.titleIllustration} src={illustrationJoinUs} alt={"Travailler chez KOLABS"} />
            </div>
            <p className={styles.intro}>
                Nous ne recrutons pas pour une mission, nous recrutons pour une carrière
            </p>

            <div className={styles.contentContainer}>
                <img className={styles.background} src={backgroundWhyJoiningUs} alt="Pourquoi rejoindre KOLABS" />
                <div className={styles.singleContentBlock}>
                    <div className={styles.singleContentBlockTitle}>TU NE SERAS JAMAIS UN NUMÉRO</div>
                    <p className={styles.singleContentText}>Chez KOLABS, tout le monde te connait et tu connais tout le monde.
                        Notre taille et notre organisation font notre force. Nous avons à cœur d'employer tous les moyens pour que tu te sentes bien chez nous.</p>
                </div>
                <div className={styles.singleContentBlock}>
                    <div className={styles.singleContentBlockTitle}>UNE EQUIPE, UN BUT</div>
                    <p className={styles.singleContentText}>Chez KOLABS, on s'entraide tous les jours. Tu es junior sur une mission ?
                        Pas de panique, un collaborateur senior sera là pour t'accompagner.
                        Notre taille humaine rend les échanges simples !</p>
                </div>
                <div className={styles.singleContentBlock}>
                    <div className={styles.singleContentBlockTitle}>TA DIFFERENCE EST UN ATOUT </div>
                    <p className={styles.singleContentText}>KOLABS se veut une entreprise libérée et inclusive.
                        Nous privilégions le savoir, le savoir-être, le savoir-faire et rien d'autre.
                        La diversité des collaborateurs créé une émulsion qui fait avancer l'entreprise. </p>
                </div>
            </div>
        </div>
    )

}

export default JoinUs; 
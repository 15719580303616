import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import KolabsStep from "../components/kolabsStep/KolabsStep";
import MainLayout from "../components/mainLayout/MainLayout";
import MeetUsBlock from "../components/meetUsBlock/MeetUsBlock";
import CarouselPartners from "../components/carousel/CarouselPartners";
import * as styles from "./index.module.scss"
//=====================================
import cvGgroupIllustration from "../assets/illustrations/cvGroup.svg";
import loopIllustration from "../assets/illustrations/loop.svg";
import checkIllustration from "../assets/illustrations/check.svg";
import meetingIllustration from "../assets/illustrations/meeting.svg";
import followUpIllustration from "../assets/illustrations/followUp.svg";
import backgroundSteps from "../assets/illustrations/backgroundSteps.svg"
import backgroundOffers from "../assets/illustrations/backgroundOffers.svg";
import backgroundIBlocks from "../assets/illustrations/backgroundIBlocks.svg";
import OfferBlock from "../components/offerBlock/OfferBlock";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import OurBranches from "../components/ourBranches/OurBranches";
import Implementations from "../components/implementations/Implemantations";

//=====================================

import MainIntro from "../components/mainIntro/MainIntro";
import { OurValues, OurValuesResponsive } from "../components/ourValues/OurValue";
import SectionTitleColored from "../components/sectionTitle/SectionTitleColored";
import JoinUs from "../components/joinUs/JoinUs";
import TeamCarousel from "../components/team/TeamCarousel";
import { Helmet } from "react-helmet";

const IndexPage = () => {

  return (
    <MainLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KOLABS - Révélateurs de talents</title>
        <html lang="fr" prefix="og: https://kolabs-web-site.netlify.app/"></html>

        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"></meta>
        <meta name="description" content="KOLABS c&apos;est une entreprise s&apos;adapte à vos besoins et vous permet de trouver les champions de l&apos;IT. KOLABS est votre partenaire pour acquérir rapidement et durablement de nouveaux talents." />
        <meta name="keywords" content="ESN, entreprise, client, freelance, cybersécurité, développement web, développement d'application, CDI, tech, paris, lyon, IT" />
        <meta name="author" content="KOLABS" />
        <meta name="copyright" content="KOLABS" />
        <meta name="robots" content="index,follow" />

        {/* OG */}
        {/* ===================================================================== */}
        <meta property="og:title" content="KOLABS - Révélateurs de talents" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kolabs-web-site.netlify.app/" />
        <meta property="og:image" content={"https://i.imgur.com/KNXRGrg.png"} />
        <meta property="og:image:width" content="150" />
        <meta property="og:image:width" content="50" />

        {/* Additional OG */}
        {/* ===================================================================== */}
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:site_name" content="KOLABS - Révélateurs de talents" />


        {/* <!-- HTML Meta Tags --> */}
        <title>KOLABS - Révélateurs de talents</title>
        <meta name="description" content="KOLABS c'est une entreprise qui s'adapte à vos besoins et vous permet de trouver les champions de l'IT. KOLABS est votre partenaire pour acquérir rapidement et durablement de nouveaux talents." />
        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://kolabs-web-site.netlify.app/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="KOLABS - Révélateurs de talents" />
        <meta property="og:description" content="KOLABS c'est une entreprise qui s'adapte à vos besoins et vous permet de trouver les champions de l'IT. KOLABS est votre partenaire pour acquérir rapidement et durablement de nouveaux talents." />
        <meta property="og:image" content={"https://i.imgur.com/KNXRGrg.png"} />
        <meta property="og:image:width" content="150" />
        <meta property="og:image:width" content="50" />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="kolabs-web-site.netlify.app" />
        <meta property="twitter:url" content="https://kolabs-web-site.netlify.app/" />
        <meta name="twitter:title" content="KOLABS - Révélateurs de talents" />
        <meta name="twitter:description" content="KOLABS c'est une entreprise qui s'adapte à vos besoins et vous permet de trouver les champions de l'IT. KOLABS est votre partenaire pour acquérir rapidement et durablement de nouveaux talents." />
        <meta name="twitter:image" content="https://i.imgur.com/KNXRGrg.png" />
        <meta name="og:image:width" content="150" />
        <meta name="og:image:width" content="50" />
      </Helmet>
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section>
        <MainIntro />
      </section>
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section className={styles.ourValuesSection}>
        <SectionTitleColored text={"Nos valeurs"} />
        <OurValues />
      </section>


      <section className={styles.ourValuesSectionResponsive}>
        <SectionTitle text={"Nos valeurs"} />
        <div>
          <OurValuesResponsive />
        </div>
      </section>
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      <section id="sectionJoinUs_id" className={styles.sectionWhyJoinUs}>
        <JoinUs />
      </section>

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      <section className={styles.sectionIBlocks}>
        <div className={styles.sectionIBlockTitleContainer}>
          <SectionTitle text={"Des avantages liés à ton profil"} />
        </div>
        <div className={styles.iBlocksContainer}>
          <img className={styles.backgroundIBlocks} src={backgroundIBlocks} alt="Des avantages liées à ton profil" />
          <div className={styles.iblocks}>
            <OfferBlock
              title={"Je suis salarié"}
              contentTitle={"Intégrer la grande famille KOLABS c'est bénéficier :"}
              content={[
                "D'un compte partagé Netflix",
                "D'un abonnement vélo électrique (Velib' max…)",
                "D'un CE qui claque avec des avantages concrets",
                "D'un accès à nos offres de formation",
                "D'un coach dédié qui t'accompagne au quotidien",
                "De moments de partage lors d'événement internes",
                "D'un salaire attractif",
                "De revenus additionnels (primes de cooptation et de mission)"]}
            />
            <OfferBlock
              title={"Je suis freelance"}
              contentTitle={"Intégrer la grande famille KOLABS c'est bénéficier :"}
              content={[
                "D'un compte partagé Netflix",
                "D'un abonnement vélo électrique (Velib' max…)",
                "D'un CE qui claque avec des avantages concrets",
                "D'un accès à nos offres de formation",
                "D'un coach dédié qui t'accompagne au quotidien",
                "De moments de partage lors d'événement internes",
                "De revenus additionnels (primes de cooptation et de mission)",
              ]}
            />
            <OfferBlock
              title={"Je souhaite devenir freelance"}
              contentTitle={"Se lancer dans l'aventure et rejoindre la famille des freelances avec KOLABS c'est la garantie d'être bien accompagné ! KOLABS a conçu tout spécialement son kit Freelance qui comprend :"}
              content={[
                "Un accompagnement dédié à la création",
                "Une aide au choix de la structure",
                "Des conseils sur les offres de comptabilité et sur les logiciels de facturation",
                "L'accès à une offre de mutuelle",
                "Des facilités de paiements au démarrage de l'activité afin de constituer rapidement de la trésorerie (paiement à émission de la facture le premier mois, avec possibilité d'émettre ses factures tous les 5 jours)",
              ]}
            />
          </div>
        </div>
      </section>


      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section className={styles.sectionJoinus}>
        <SectionTitle text={"Envie de rejoindre KOLABS "} />
        <div className={styles.joinusLinks}>
          <a className={styles.buttonLink} href="https://www.welcometothejungle.com/fr/companies/kolabs/jobs" rel="noopener noreferrer" target={"_blank"}>Découvrir nos offres</a>
          <a className={styles.buttonLink} href="https://www.welcometothejungle.com/fr/companies/kolabs/jobs/candidatures-spontanees" rel="noopener noreferrer" target={"_blank"}>Candidature spontanée </a>
        </div>
      </section>

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      <section id="sectionTeam_id" className={styles.sectionTeam}>
        <SectionTitle text={"Équipe"} />
        <TeamCarousel />
      </section>



      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      <section className={styles.sectionImplentations}>
        <SectionTitle text={"2 Implantations"} />
        <Implementations />
      </section>

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      <section id="sectionSteps_id" className={styles.sectionSteps}>
        <SectionTitle text={"L'expérience KOLABS"} />
        <div className={styles.steps}>
          <div className={styles.backgroundStepsContainer}>
            <img alt="section des étape pour intégrer KOLABS" className={styles.stepsIllustration} src={backgroundSteps} />
            <div className={styles.stepsMainTextContainer + " " + styles.stepsMainText}>
              Afin de répondre au mieux au besoin exprimé, nous avons mis en place un suivi personnalisé faisant état de l'avancée du projet en temps réel.
              Vous êtes régulièrement mis au courant des différentes étapes de notre recherche et nous vous proposons rapidement des profils pouvant correspondre à vos attentes.
            </div>
          </div>

          <div className={styles.stepsContainer}>
            <KolabsStep
              stepMainTitle={"Étape 1 :"}
              stepName={"Définir votre besoin"}
              image={meetingIllustration}
              imageAlt={"Définir votre besoin"}
              text={"Décrivez nous le profil recherché, les missions à effectuer, les compétences requises, ainsi que le contexte de la mission et on se charge de réaliser votre fiche de poste."}
            />

            <KolabsStep
              stepMainTitle={"Étape 2 :"}
              stepName={"Recherche du champion"}
              image={loopIllustration}
              imageAlt={"Rechercher un consultant"}
              text={"Une équipe d'experts dédiés est mobilisée et effectue les premières recherches dans notre base de données de plus de 80 000 profils IT afin de sélectionner des profils qui auront à la fois les compétences techniques, et un état d'esprit correspondant à vos valeurs."}
            />
            <KolabsStep
              stepMainTitle={"Étape 3 :"}
              stepName={"Notre recommandation"}
              image={cvGgroupIllustration}
              imageAlt={"Notre recommandation pour votre entreprise"}
              text={"Nous vous partageons notre sélection de profils avec des CVs clairs et standardisés accompagnés d'une note additionnelle « l'avis KOLABS » basé sur l'entrevue effectuée dans nos locaux et les tests techniques réalisés."}
            />
            <KolabsStep
              stepMainTitle={"Étape 4 :"}
              stepName={"Validez et signez"}
              image={checkIllustration}
              imageAlt={"Valider et signez un contrat électronique"}
              text={"Un de nos collaborateurs vous a tapé dans l'œil ? Nos tarifs sont simples et transparents ; nos contrats sont soumis à signature électronique."}
            />
            <KolabsStep
              stepName={"Le suivi"}
              image={followUpIllustration}
              imageAlt={"Le suivi de nos consultants"}
              text={"Notre collaboration ne s'arrête pas une fois le meilleur profil trouvé. Afin que chaque mission soit un succès, nous avons mis en place un véritable mentoring. Notre success manager assure le suivi de tous nos champions en mission de manière régulière et veille à ce que la mission soit en adéquation avec ses compétences et ses attentes. \n\nNotre success manager s'occupe également de faire le point avec vous sur l'intégration de notre champion au sein de votre équipe et de sa valeur ajoutée, et échange régulièrement afin de s'assurer du bon déroulement de la mission."}
            />
          </div>
        </div>
      </section>

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section className={styles.offersSection}>
        <div className={styles.offersSectionTitle}>
          <SectionTitle text={"3 Offres"} />
        </div>
        <div className={styles.offersSupContainer}>
          <img alt="section les offres de KOLABS" className={styles.offersBackground} src={backgroundOffers} />
          <div className={styles.offersContainer}>
            <OfferBlock
              title={"Prestation"}
              titleExplanation={"Vous recherchez un collaborateur pour une mission courte ou longue ? C'est l'offre qu'il vous faut !"}
              contentTitle={"Vous recherchez un pro fil spécifique pour une mission particulière ? Un profil rare ou très demandé ? Nous mettons notre savoir-faire et nos compétences à votre service."}
              contentInitialization={"L'offre de prestation vous permet :"}
              content={["De bénéficier d'un accompagnement personnalisé (de la rédaction de la fiche de post au suivi du collaborateur qui intègre votre équipe)",
                "D'accéder à notre base de profils qualifiés que vous ne trouverez nulle part ailleurs",
                "De rencontrer rapidement des candidats pertinents Cette offre c'est la garantie de faciliter votre recherche et de trouver le collaborateur qu'il vous faut !"]}
              contentConclusion={"Cette offre c'est la garantie de faciliter votre recherche et de trouver le collaborateur qu'il vous faut !"}
            />

            <OfferBlock
              title={"Pré-embauche"}
              titleExplanation={"Vous recherchez un collaborateur en mission pour quelques mois avant de l'internaliser ? C'est l'offre qu'il vous faut !"}
              contentTitle={"L'offre de pré-embauche vous permet de : "}
              content={["Tester les compétences du potentiel futur salarié et de l'évaluer techniquement, sans engagement",
                "Capitaliser sur la formation dispensée au collaborateur : le savoir reste dans l'entreprise",
                "Réduire le processus de recrutement",
                "(Dépasser les budgets fixés pas les ressources humaines"]}
              contentConclusion={"Comme pour une période d'essai, c'est aussi la possibilité pour notre champion de faire ses preuves et de s'assurer que la mission lui convient."}
            />

            <OfferBlock
              title={"Embauche directe"}
              titleExplanation={"Vous recherchez un collaborateur pour rejoindre votre équipe en tant que salarié ? C'est l'offre qu'il vous faut !"}
              contentInitialization={"Vous recherchez un profil spécifique pour renforcer vos équipes et peinez à trouver la perle rare ? Nous mettons notre savoir-faire et nos compétences à votre service."}
              contentTitle={"L'offre d'embauche directe vous permet :"}
              content={["D'accéder à notre base de profils qualifiés que vous ne trouverez nulle part ailleurs",
                "De gagner un temps précieux lors des processus de recrutement et de suivi de candidatures"
              ]}
              contentConclusion={"Cette offre c'est la garantie de faciliter votre recherche et de trouver le collaborateur qu'il vous faut !"}
            />
          </div>
        </div>
      </section>


      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section className={styles.sectionContactUs} >
        <div className={styles.contactUsContainer}>
          <div className={styles.contactUsText}>Vous êtes à la recherche d'un champion ?</div>
          <a className={styles.contactUsButtonLink} href="https://docs.google.com/forms/d/e/1FAIpQLSdoWLFz2-rwPwELQVinCYJd4bFdbkrss87I_ZixtHFLwLxk4g/viewform" rel="noopener noreferrer" target={"_blank"}>Contactez nous</a>
        </div>
      </section>

      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}

      <section className={styles.sectionOurBranches}>
        <div>
          <SectionTitle text={"2 Business Unit spécialisées"} />
          <OurBranches />
        </div>
      </section>
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section className={styles.partnersCarousel}>
        <SectionTitle text={"Ils nous font confiance"} />
        <CarouselPartners />
      </section>
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@à */}
      <section id={"sectionMeetUs_id"}>
        <div className={styles.sectionMeetUs}>
          <MeetUsBlock />
        </div>
      </section>
    </MainLayout >
  );
};
export default IndexPage;

import React from "react";
import * as styles from "./KolabsStep.module.scss";

const KolabsStep = ({ stepMainTitle, stepName, text, image, imageAlt }) => {
    return (
        <div className={styles.container}>
            <div>
                <h3>{stepMainTitle}</h3>
                <h3>{stepName}</h3>
            </div>
            <div className={styles.infoContainer}>
                <p className={styles.text}>{text}</p>
                <img className={styles.illustration} src={image} alt={imageAlt} />
            </div>
        </div>
    )
}


export default KolabsStep;
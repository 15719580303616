import React from "react";
import * as styles from "./CarouselPartners.module.scss"
import "./index.scss"
import Slider from "react-slick";

//logos
//===============================

import logoBnp from "../../assets/images/logoBnp.webp";
import logoChristialDior from "../../assets/images/logoChristianDior.png";
import logoHeineken from "../../assets/images/logoHeinejen.webp";
import logoKering from "../../assets/images/logoKering.webp";
import logoLouisVuitton from "../../assets/images/logoLouisVuitton.webp";
import logoLvmh from "../../assets/images/logoLvmh.webp";
import logoTotal from "../../assets/images/logoTotal.webp";
import logoSodexo from "../../assets/images/logoSodexo.webp";
import logoApave from "../../assets/images/logoApave.webp";

const CarouselPartners = () => {
    const imgs = [
        {
            src: logoLouisVuitton,
            alt: "Client Kolabs Louis vuitton"
        },
        {
            src: logoKering,
            alt: "Client Kolabs Kering"
        },
        {
            src: logoHeineken,
            alt: "Client ESN Kolabs Heineken"
        },
        {
            src: logoLvmh,
            alt: "Client Kolabs LVMH"
        },
        {
            src: logoTotal,
            alt: "Client Kolabs Total energie"
        },
        {
            src: logoChristialDior,
            alt: "Client de notre entreprise ESN Kolabs Christian Dior"
        },
        {
            src: logoBnp,
            alt: "Client Kolabs Bnp paribas"
        },

        {
            src: logoSodexo,
            alt: "Client Kolabs securité"
        },
        {
            src: logoApave,
            alt: "Client Kolabs Apave"
        },
    ]

    const PrevArrow = ({ className, onClick }) => {
        return (
            <button className={className + " " + styles.arrowPrev} onClick={onClick}>
                <svg className={styles.arrowButton} id="Component_8_1" data-name="Component 8 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.025 105.245">
                    <path id="Path_1430" data-name="Path 1430" d="M6373.142,575.816l-37.213-37.128,16.281-15.494,51.744,52.623-51.744,52.623-16.281-17.248Z" transform="translate(6403.954 628.439) rotate(-180)" />
                </svg>
            </button>
        )
    }

    const NextArrow = ({ className, onClick }) => {
        return (
            <button className={className + " " + styles.arrowNext} onClick={onClick}>
                <svg className={styles.arrowButton} id="Component_7_1" data-name="Component 7 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.025 105.245">
                    <path id="Path_1429" data-name="Path 1429" d="M6373.142,575.816l-37.213-37.128,16.281-15.494,51.744,52.623-51.744,52.623-16.281-17.248Z" transform="translate(-6335.929 -523.194)" />
                </svg>
            </button>
        )
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        infinite : true,
        arrows: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                },
            },
        ]
    };


    //https://stackoverflow.com/questions/54985215/how-to-add-slick-active-class-in-slider-dots-in-react-slick
    return (
        <div className={styles.container}>
            <Slider {...settings}>
                {
                    imgs.map((el, key) => {
                        return (
                            <div key={key} className={styles.singleLogoContainer}>
                                <img
                                    src={el.src}
                                    alt={el.alt}
                                    className={styles.singleLogo}
                                />
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default CarouselPartners;
import React from "react";
import * as styles from "./TeamCarousel.module.scss";
import "./index.scss";
import Slider from "react-slick";

//=========================================
import AlexisAvatar from "../../assets/images/team/Alexis.webp";
import BereniceAvatar from "../../assets/images/team/Berenice.webp";
import LouAvatar from "../../assets/images/team/Lou.webp";
import MaevaAvatar from "../../assets/images/team/Maeva.webp";
import MarilynAvatar from "../../assets/images/team/Marilyn.webp";
import MarionAvatar from "../../assets/images/team/Marion.webp";
import MathildeAvatar from "../../assets/images/team/Mathilde.webp";
import RaphaelAvatar from "../../assets/images/team/Raphaël.webp";
import SarahAvatar from "../../assets/images/team/Sarah.webp";
import SelimAvatar from "../../assets/images/team/Selim.webp";
import SophieAvatar from "../../assets/images/team/Sophie.webp";
import SirageAvatar from "../../assets/images/team/sirage.webp";
//=========================================

const TeamCarousel = () => {

    const imgs = [
        {
            src: AlexisAvatar,
            alt: "Photos de notre collaborateur KOLABS Alexis Declair",
            hoverText: "Alexis",
            role: "Graphiste"
        },
        {
            src: BereniceAvatar,
            alt: "Photo de notre collaboratrice KOLABS Berenice Cazau",
            hoverText: "Berenice",
            role: "Head of sales & General Manager"
        },
        {
            src: LouAvatar,
            alt: "Photo de notre collaboratrice KOLABS Lou Lelouran",
            hoverText: "Lou",
            role: "Sales & Talent Finder"
        },
        {
            src: MaevaAvatar,
            alt: "Photo de notre collaboratrice KOLABS Maëva Amsellem",
            hoverText: "Maëva",
            role: "Talent Finder"
        },
        {
            src: MarilynAvatar,
            alt: "Photo de notre collaboratrice KOLABS Marilyn Guillaumont",
            hoverText: "Marilyn",
            role: "HR Officer"
        },
        {
            src: MarionAvatar,
            alt: "Photo de notre collaboratrice KOLABS Marion Viou",
            hoverText: "Marion",
            role: "Head of Communication and Talent Finder"
        },
        {
            src: MathildeAvatar,
            alt: "Photo de notre collaboratrice KOLABS Mathilde Guillemot",
            hoverText: "Mathilde",
            role: "Chief Operating Officer"
        },

        {
            src: RaphaelAvatar,
            alt: "Photos de notre collaborateur KOLABS Raphaël Deluard",
            hoverText: "Raphaël",
            role: "CISO"
        },
        {
            src: SarahAvatar,
            alt: "Photo de notre collaboratrice KOLABS Sarah Ould Ouali",
            hoverText: "Sarah",
            role: "Tech Lead Data, Dev et IA"
        },
        {
            src: SelimAvatar,
            alt: "Photos de CEO de KOLABS Selim Boudis",
            hoverText: "Selim",
            role: "Founder & CEO"
        },
        {
            src: SirageAvatar,
            alt: "Photo de notre collaborateur KOLABS Sirage AL DBIYAT",
            hoverText: "Sirage",
            role: "Full stack developer"
        },
        {
            src: SophieAvatar,
            alt: "Photo de notre collaboratrice KOLABS Sophie Da Cruz",
            hoverText: "Sophie",
            role: "Office Manager"
        }
    ]
    const PrevArrow = ({ className, onClick }) => {
        return (
            <button className={className + " " + styles.arrowPrev} onClick={onClick}>
                <svg className={styles.arrowButton} id="Component_8_1" data-name="Component 8 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.025 105.245">
                    <path id="Path_1430" data-name="Path 1430" d="M6373.142,575.816l-37.213-37.128,16.281-15.494,51.744,52.623-51.744,52.623-16.281-17.248Z" transform="translate(6403.954 628.439) rotate(-180)" />
                </svg>
            </button>
        )
    }

    const NextArrow = ({ className, onClick }) => {
        return (
            <button className={className + " " + styles.arrowNext} onClick={onClick}>
                <svg className={styles.arrowButton} id="Component_7_1" data-name="Component 7 – 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.025 105.245">
                    <path id="Path_1429" data-name="Path 1429" d="M6373.142,575.816l-37.213-37.128,16.281-15.494,51.744,52.623-51.744,52.623-16.281-17.248Z" transform="translate(-6335.929 -523.194)" />
                </svg>
            </button>
        )
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        arrows: true,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
        ]
    };
    return (
        <div className={styles.container}>
            <Slider {...settings}>
                {
                    imgs.map((el, key) => {
                        return (
                            <div key={key} className={styles.singleAvatarContainer}>
                                <div className={styles.overlay}>
                                    <div>
                                        {el.hoverText}
                                    </div>
                                    <div className={styles.role}>
                                        {el.role}
                                    </div>
                                </div>
                                <img
                                    src={el.src}
                                    alt={el.alt}
                                    className={styles.singleAvatar}
                                />
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}


export default TeamCarousel;